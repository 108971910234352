import React, {FC} from 'react';
import './ResearchItem.css';

interface ResearchItemProps {
    name: string,
    description: string,
    cost: number,
    isUnlocked: boolean,
    isResearchable: boolean,
    wasResearched: boolean | undefined,
    onResearchClicked: (cost: number) => boolean
}

const ResearchItem: FC<ResearchItemProps> = (props) => {
    if (props.isUnlocked || props.wasResearched) {
        return (
            <div className="ResearchItem">
                <div className="description">"{props.description}"</div>
                <button className={props.wasResearched ? "hidden" : ""}
                        onClick={() => props.onResearchClicked(props.cost)}
                        disabled={!props.isResearchable}
                        type={"submit"}>Research {props.name} for {props.cost.toLocaleString()}$
                </button>
                <button className={props.wasResearched ? "" : "hidden"}
                        type={"submit"} disabled> {props.name} already researched
                </button>
            </div>);
    } else {
        return (
            <div className="ResearchItem placeholder">
                <div className="description-placeholder">Hire more different types of employees to unlock other research
                    areas.
                </div>
            </div>
        );
    }

}

export default ResearchItem;
