import React, {FC, useEffect, useState} from 'react';
import {clearInterval, setInterval} from 'worker-timers';
import './WorkerItem.css';
import 'react-circular-progressbar/dist/styles.css';
import {CircularProgressbar} from "react-circular-progressbar";


interface WorkerItemProps {
    name: string,
    speed: number,
    amount: number,
    hireCost: number,
    reductionRate: number,
    timesHireable: number,
    isUnlocked: boolean,
    isUpgraded: boolean,
    isSynergy: boolean,
    onCycleComplete: (amount: number) => void
    onHireClicked: (cost: number) => boolean
}

interface TriggeredHire {
    date: Date;
    hireCost: string;
}

const WorkerItem: FC<WorkerItemProps> = (props: WorkerItemProps) => {
    const [progress, setProgress] = useState(loadProgress());
    const [hires, setHires] = useState(loadHires());
    const [triggeredCycle, setTriggeredCycle] = useState<Date>();
    const [triggeredHire, setTriggeredHire] = useState<TriggeredHire>();
    const [triggeredDeath, setTriggeredDeath] = useState<Date>();

    function executeReduction() {
        if ((Math.random() * 100) < props.reductionRate) {
            const newHiresCount = hires - 1;
            setHires(newHiresCount);
            setTriggeredDeath(new Date());
            localStorage.setItem("resource-" + props.name + '-hires', newHiresCount.toString())
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            clearTriggers()
            if (props.isUnlocked && hires > 0) {
                incrementProgress();
                executeReduction()
            } else {
                setProgress(0);
                localStorage.setItem("resource-" + props.name + '-progress', "0")
            }
        }, 100);

        return () => {
            clearInterval(interval);
        };
    });

    function clearTriggers() {
        if (triggeredHire) {
            const now = new Date();
            const diffInMilli = now.valueOf() - triggeredHire.date.valueOf();

            if (diffInMilli > 400) {
                setTriggeredHire(undefined);
            }
        }
        if (triggeredCycle) {
            const now = new Date();
            const diffInMilli = now.valueOf() - triggeredCycle.valueOf();

            if (diffInMilli > 800) {
                setTriggeredCycle(undefined);
            }
        }
        if (triggeredDeath) {
            const now = new Date();
            const diffInMilli = now.valueOf() - triggeredDeath.valueOf();

            if (diffInMilli > 1500) {
                setTriggeredDeath(undefined);
            }
        }
    }

    function incrementProgress() {
        const upgradeFactor = props.isUpgraded ? 5 : 1;
        const newProgressValue = progress + ((props.speed * upgradeFactor) / 10);


        if (newProgressValue > 100) {
            setProgress(0);
            localStorage.setItem("resource-" + props.name + '-progress', "0")
            if (props.onCycleComplete) {
                props.onCycleComplete(props.amount * hires * (props.isSynergy ? 2 : 1));
                setTriggeredCycle(new Date());
            }
        } else {
            setProgress((newProgressValue));
            localStorage.setItem("resource-" + props.name + '-progress', newProgressValue.toString())
        }
    }

    function loadHires() {
        const storedHires = localStorage.getItem("resource-" + props.name + '-hires');

        if (storedHires) {
            return Number.parseInt(storedHires);
        } else {
            return 0;
        }
    }

    function loadProgress() {
        const storedProgress = localStorage.getItem("resource-" + props.name + '-progress');

        if (storedProgress) {
            return Number.parseInt(storedProgress);
        } else {
            return 0;
        }
    }

    function round(num: number) {
        return Math.round(num * 100) / 100;
    }

    const hire = () => {
        const hireCost = props.hireCost * props.timesHireable;
        if (props.onHireClicked(hireCost)) {
            const newHiresCount = hires + props.timesHireable;
            setHires(newHiresCount);
            setTriggeredHire({
                date: new Date(),
                hireCost: hireCost.toLocaleString()
            });
            localStorage.setItem("resource-" + props.name + '-hires', newHiresCount.toString())
        }
    }

    if (props.isUnlocked) {
        return (
            <div className="Item">
                <div className="Item-circle">
                    <CircularProgressbar value={progress}/>
                    <img className="avatar" src={props.name + ".png"}></img>
                    <div
                        className={triggeredCycle ? 'animated-amount' : 'hidden-amount'}>+{(props.amount * hires * (props.isSynergy ? 2 : 1)).toLocaleString()}$
                    </div>
                    <div
                        className={triggeredHire ? 'animated-amount negative' : 'hidden-amount'}>-{triggeredHire?.hireCost}$
                    </div>
                    <div className={triggeredDeath ? 'avatar animated-death' : 'hidden-amount'}>
                        <img src="death.png"></img>
                    </div>
                </div>

                <div className={triggeredDeath ? 'hire-animation' : ''}>
                    <div
                        className={triggeredDeath ? 'death-text' : triggeredHire ? 'hire-text' : ''}>
                        <span className="type-title">{props.name} ({hires})</span>
                    </div>
                </div>
                <div className={hires > 0 ? "stats" : "stats invisible"}><span
                    className={props.isSynergy ? 'synergy' : ''}>{props.amount * hires * (props.isSynergy ? 2 : 1)}$</span> per
                    delivery cycle
                </div>
                <div
                    className={hires > 0 ? "stats" : "stats invisible"}>
                    <span
                        className={props.isUpgraded ? 'upgraded' : ''}>{round(100 / (props.speed * (props.isUpgraded ? 5 : 1)))}</span> seconds
                    per delivery cycle
                </div>
                <div className="hire-button">
                    {props.timesHireable < 1 ?
                        (<button disabled onClick={() => hire()}>Hire
                            x1 for {(props.hireCost).toLocaleString()}$
                        </button>) :
                        (<button onClick={() => hire()}>Hire {props.timesHireable}x
                            for {(props.hireCost * props.timesHireable).toLocaleString()}$
                        </button>)
                    }

                </div>
            </div>);
    } else {
        return <div className="Item placeholder">
            <div className="Item-circle">
                <div className="question-mark">
                </div>
            </div>
            <div className="subtext">Increase your workforce before the next employee type is unlocked.</div>
        </div>
    }
};

export default WorkerItem;
