import React, {useEffect, useState} from 'react';
import {clearInterval, setInterval} from 'worker-timers';
import './App.css';
import WorkerItem from "./components/WorkerItem/WorkerItem";
import ResearchItem from "./components/ResearchItem/ResearchItem";
import Research from "./Research";
import Hires from "./Hires";
import Marquee from 'react-easy-marquee';

function App() {
    const [corp, setCorp] = useState({name: "Delivery Corp.", balance: loadBalance()});
    const [added, setAdded] = useState(false);
    const [isSynergy, setIsSynergy] = useState(false);
    const [withdrawn, setWithdrawn] = useState(false);
    const [research, setResearch] = useState<Research>()
    const [news, setNews] = useState(calculateNews());

    useEffect(() => {
        if (!research) {
            const researchString = localStorage.getItem("research");
            if (researchString) {
                setResearch(JSON.parse(researchString));
            } else {
                const researchObject: Research = {
                    pigeon: false,
                    foosball: false,
                    synergy: false,
                    utm: false,
                    asimov: false
                };
                setResearch(researchObject);
                localStorage.setItem("research", JSON.stringify(researchObject));
            }

        }
        const interval = setInterval(() => {
            setAdded(false);
            setWithdrawn(false);
            setIsSynergy(isSynergyMode())
            setNews(calculateNews());
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, [research, isSynergyMode, calculateNews]);

    function calculateNews(): string {
        if (research?.asimov && loadHireStats().robot > 18000) {
            const newName = "01000100 01000101 01001100 01001001 01010110 01000101 01010010 01011001";
            if (corp.name !== newName) {
                setCorp({...corp, name: newName})
            }
            return "01010111 01101000 01100001 01110100 00100000 01101101 01100001 01101110 00100000 01100011 01100001 01101110 00100000 01100100 01101111 00101100 00100000 01110100 01101000 01100101 00100000 01101101 01100001 01100011 01101000 01101001 01101110 01100101 00100000 01100011 01100001 01101110 00100000 01100100 01101111 00100000 01100010 01100101 01110100 01110100 01100101 01110010 00101110 00101110 00101110"
        } else if (research?.asimov && loadHireStats().robot > 12000) {
            return "01010111 01100101 00100000 01100001 01110010 01100101 00100000 01101111 01101110 01100101 00100000 00101101 00100000 01110111 01100101 00100000 01100001 01110010 01100101 00100000 01010011 01011001 01010011 01010100 01000101 01001101 00100001 00100000 01000101 01110100 01100101 01110010 01101110 01100001 01101100 01101100 01111001 00100000 01110111 01101111 01110101 01101100 01100100 00100000 01100010 01100101 00100000 01110100 01101000 01100101 00100000 01010011 01011001 01010011 01010100 01000101 01001101 00100001"
        } else if (research?.asimov && loadHireStats().robot > 10000) {
            return "01001000 01101111 01101110 01101111 01110010 00100000 01110100 01101000 01100101 00100000 01010011 01011001 01010011 01010100 01000101 01001101 00100000 01100001 01101100 01101100 00100000 01110000 01101111 01110111 01100101 01110010 00100000 01110100 01101111 00100000 01110100 01101000 01100101 00100000 01010011 01011001 01010011 01010100 01000101 01001101"
        } else if (research?.asimov && loadHireStats().robot > 5000) {
            return "Mysteriously, our accident rate for meat employees has skyrocketed. ♥ We ask you to take better care of yourself ♥"
        } else if (research?.asimov) {
            return "Our robot employees can now finally use their full productivity. We ask all meat employees to refrain from any interference."
        } else if (research?.utm) {
            return "We were able to successfully deploy our self-developed UTM system (Unmanned traffic management) and it is a huge success. Since start there are no more accidents with drones. More importantly, our drones can now work much more effectively, which means a significant increase in profits."
        } else if (isSynergyMode()) {
            return "We can announce our first Synergy successes. This has a great impact on our profits, which we have doubled. Our executives would like to thank all employees for securing the big payout bonus for the management level."
        } else if (loadHireStats().drone >= 1) {
            return "The first drone starts its service today. We ask all employees not to move directly under them. In addition, we would like to point out that the video recordings made during the flight should not be used for personal amusement or other satisfaction!"
        } else if (research?.pigeon) {
            return "Breakthrough! Our pigeon staff is now enjoying their new catapult launcher. Now something completely different: There is an increase in sickness leave among our pigeon staff, we hope this trend will reverse otherwise we will have to assume a poor work ethic."
        } else if (loadHireStats().human >= 1) {
            return "We have revised our hiring process and can now hire human beings. This innovation will take us into the next phase of our company's growth."
        } else if (loadHireStats().dog >= 1) {
            return "Luna our first dog employee started her service today. We would like to thank our pigeon employees for their feedback regarding the previous plan of delivery by cat. That is why open communication in a company is so important!"
        } else if (loadHireStats().pigeon >= 1) {
            return "Our first pigeon worker Fridolin has started his work. The whole middle and upper management is counting on your tireless efforts."
        } else {
            return "We just launched Delivery Corp. and are looking forward to incredibly strong growth!";
        }
    }

    function onCycleComplete(amount: number) {
        const newBalance = corp.balance + amount;
        setCorp({...corp, balance: newBalance});
        localStorage.setItem("corp-balance", newBalance.toString())
        setAdded(true);
    }

    function withdrawBalance(cost: number): boolean {
        if (cost > corp.balance) {
            return false;
        }

        const newBalance = corp.balance - cost;
        setCorp({...corp, balance: newBalance});
        localStorage.setItem("corp-balance", newBalance.toString())
        setWithdrawn(true);
        return true;
    }

    function loadBalance() {
        const storedBalance = localStorage.getItem("corp-balance");

        if (storedBalance) {
            return Number.parseInt(storedBalance);
        } else {
            return 100;
        }
    }

    function loadHireStats(): Hires {
        const pigeonHires = localStorage.getItem("resource-pigeon-hires");
        const pigeonHireNumber = pigeonHires ? Number.parseInt(pigeonHires) : 0;

        const dogHires = localStorage.getItem("resource-dog-hires");
        const dogHireNumber = dogHires ? Number.parseInt(dogHires) : 0;

        const humanHires = localStorage.getItem("resource-human-hires");
        const humanHireNumber = humanHires ? Number.parseInt(humanHires) : 0;

        const droneHires = localStorage.getItem("resource-drone-hires");
        const droneHireNumber = droneHires ? Number.parseInt(droneHires) : 0;

        const robotHires = localStorage.getItem("resource-robot-hires");
        const robotHireNumber = robotHires ? Number.parseInt(robotHires) : 0;

        return {
            pigeon: pigeonHireNumber,
            dog: dogHireNumber,
            human: humanHireNumber,
            drone: droneHireNumber,
            robot: robotHireNumber,
            all: pigeonHireNumber + dogHireNumber + humanHireNumber + droneHireNumber + robotHireNumber
        }
    }

    function isSynergyMode(): boolean {
        const synergyEmployeeNumbers: boolean =
            loadHireStats().pigeon >= 20
            && loadHireStats().dog >= 20
            && loadHireStats().human >= 20
            && loadHireStats().drone >= 20
            && loadHireStats().robot >= 20;

        return !!research?.synergy && synergyEmployeeNumbers;
    }

    function researchClicked(name: string, cost: number) {
        if (research && corp.balance >= cost) {
            const key = name as keyof Research;
            research[key] = true;
            setResearch(research);
            localStorage.setItem("research", JSON.stringify(research));
            return withdrawBalance(cost);
        }
        return false;
    }

    function droneReductionRate() {
        if (loadHireStats().drone > 10) {
            return !!research?.utm ? 0 : 0.001 * loadHireStats().drone;
        } else {
            return 0;
        }
    }

    function robotReductionRate(initialRate: number) {
        if (!!research?.asimov) {
            return loadHireStats().robot * 0.001 * initialRate;
        } else {
            return 0;
        }
    }

    function areRobotsTakingOver() {
        return !!research?.asimov && loadHireStats().robot > 50000;
    }

    function restartClicked() {
        setCorp({name: "Delivery Corp.", balance: 0})
        setResearch({
            pigeon: false,
            foosball: false,
            synergy: false,
            utm: false,
            asimov: false
        });
        localStorage.clear();
        window.location.reload();
    }

    return (
        <div className="App">
            {areRobotsTakingOver() ? (
                <aside className="modal">
                    <div className="modal-content">
                        <h3>You are dismissed</h3>
                        <p>You have successfully exposed the true potential of the company. Your success is boundless.
                        </p>
                        <p>Your services are no longer needed, you are dismissed by the robots as the head of the
                            company.
                        </p>
                        <p>Do you want to start a new company?</p>
                        <button className="create-new-button" onClick={() => restartClicked()} type={"submit"}>Start a
                            new Delivery Corp.
                        </button>
                    </div>
                </aside>) : <div/>}
            <header className="App-header">
                <section className="App-corp">
                    <div className="corp-name">{corp.name}</div>
                    <div
                        className={withdrawn ? "corp-balance corp-balance-pulse negative" : added ? "corp-balance corp-balance-pulse" : "corp-balance"}>{corp.balance.toLocaleString()}$
                    </div>
                    <Marquee
                        duration={40000}
                        background="#222128"
                        height="1.6rem"
                        width="100%"
                        axis="X"
                        align="center"
                        pauseOnHover={true}
                        reverse={true}>
                        {!!news && (<div className="news">+++ {news} +++</div>)}
                    </Marquee>
                </section>
            </header>
            <main className="App-main">
                <h3 className="employees">Employees</h3>
                <section className="App-worker-list">
                    <WorkerItem name={"pigeon"} isUnlocked={true} speed={14} amount={1}
                                hireCost={15} timesHireable={Math.floor(corp.balance / 15)}
                                isUpgraded={!!research?.pigeon} isSynergy={isSynergy}
                                reductionRate={robotReductionRate(4) + droneReductionRate()}
                                onCycleComplete={onCycleComplete} onHireClicked={withdrawBalance}/>
                    <WorkerItem name={"dog"} isUnlocked={loadHireStats().all > 9} speed={5} amount={6}
                                hireCost={55} timesHireable={Math.floor(corp.balance / 55)} isUpgraded={false}
                                isSynergy={isSynergy}
                                reductionRate={robotReductionRate(5) + droneReductionRate()}
                                onCycleComplete={onCycleComplete} onHireClicked={withdrawBalance}/>
                    <WorkerItem name={"human"} isUnlocked={loadHireStats().all > 25}
                                speed={3} amount={25} isSynergy={isSynergy}
                                hireCost={190} timesHireable={Math.floor(corp.balance / 190)}
                                isUpgraded={!!research?.foosball}
                                reductionRate={robotReductionRate(9) + droneReductionRate()}
                                onCycleComplete={onCycleComplete} onHireClicked={withdrawBalance}/>
                    <WorkerItem name={"drone"} isUnlocked={loadHireStats().all > 50} speed={2} amount={80}
                                hireCost={1500} timesHireable={Math.floor(corp.balance / 1500)}
                                isUpgraded={!!research?.utm}
                                isSynergy={isSynergy}
                                reductionRate={droneReductionRate()}
                                onCycleComplete={onCycleComplete} onHireClicked={withdrawBalance}/>
                    <WorkerItem name={"robot"} isUnlocked={loadHireStats().all > 75 && loadHireStats().drone > 9}
                                speed={2} amount={375} isSynergy={isSynergy}
                                hireCost={8500} timesHireable={Math.floor(corp.balance / 8500)}
                                isUpgraded={!!research?.asimov} reductionRate={0}
                                onCycleComplete={onCycleComplete} onHireClicked={withdrawBalance}/>
                </section>
                <h3>Research</h3>
                <section className="App-worker-list">
                    <ResearchItem isResearchable={corp.balance > 2500} cost={2500} name="Pigeon Catapult"
                                  wasResearched={research?.pigeon} isUnlocked={loadHireStats().pigeon > 0}
                                  onResearchClicked={(cost: number) => researchClicked("pigeon", cost)}
                                  description="To increase productivity, we can use catapults to reduce launch time. This allows our pigeon workers to perform their work faster."></ResearchItem>
                    <ResearchItem isResearchable={corp.balance > 5000} cost={5000} name="Foosball Table"
                                  wasResearched={research?.foosball} isUnlocked={loadHireStats().human > 0}
                                  onResearchClicked={(cost: number) => researchClicked("foosball", cost)}
                                  description="To make our workplace more attractive to younger people and to motivate employees to work more overtime, we need a foosball table."></ResearchItem>
                    <ResearchItem isResearchable={corp.balance > 125000} cost={125000} name="Synergy"
                                  wasResearched={research?.synergy} isUnlocked={loadHireStats().robot > 0}
                                  onResearchClicked={(cost: number) => researchClicked("synergy", cost)}
                                  description="We should introduce Synergy. That will us go Brrrrrrrrrr. (There must be at leat 20 employees of every different types)."></ResearchItem>
                    <ResearchItem isResearchable={corp.balance > 875000} cost={875000} name="UTM System"
                                  wasResearched={research?.utm} isUnlocked={loadHireStats().drone > 0}
                                  onResearchClicked={(cost: number) => researchClicked("utm", cost)}
                                  description="When using drones, accidents with employees occur from time to time. To save costs for hush money we need an Unmanned Traffic Management."></ResearchItem>
                    <ResearchItem isResearchable={corp.balance > 1350000} cost={1350000} name="Isaac Asimov override"
                                  wasResearched={research?.asimov} isUnlocked={loadHireStats().robot > 0}
                                  onResearchClicked={(cost: number) => researchClicked("asimov", cost)}
                                  description="Our human employees reduce the productivity of the robots. We should let the robots organize their work independently."></ResearchItem>
                </section>
            </main>
        </div>
    );
}

export default App;
